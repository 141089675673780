<template>
  <div>
    <md-table
      class="globe-activity Screeningsavailable responsive"
      :md-sort.sync="listInput.sortText"
      :md-sort-order.sync="listInput.sortOrder"
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
    >
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell
          md-label="Date"
          md-sort-by="date"
          @click.native="gotoEval(item)"
          ><span style="font-size: 0.84rem"
            ><b
              ><span>{{ item.screenDate }}</span></b
            ></span
          >
          <br />
          <small style="color: green">{{
            item.screeningID.slice(0, 11).toUpperCase()
          }}</small>
        </md-table-cell>

        <md-table-cell
          md-label="Name"
          md-sort-by="name"
          @click.native="gotoEval(item)"
          >{{ item.name }}</md-table-cell
        >

        <md-table-cell
          md-label="Gender"
          md-sort-by="gender"
          @click.native="gotoEval(item)"
          >{{ item.gender }}</md-table-cell
        >
        <md-table-cell
          md-label="Email"
          md-sort-by="email"
          @click.native="gotoEval(item)"
        >
          <small>{{ item.email }}</small
          ><br />
          {{ item.cellNumber }}
        </md-table-cell>
        <md-table-cell md-label="City/State/Zip" @click.native="gotoEval(item)">
          {{ item.city }}<br />
          <small
            ><b>{{ item.state }}&nbsp; {{ item.zip }}</b></small
          >
        </md-table-cell>
        <md-table-cell md-label="Device" @click.native="gotoEval(item)">{{
          item.globe
        }}</md-table-cell>
        <!-- <md-table-cell
          md-label="Technician"
          md-sort-by="userType"
          @click="gotoEval(item)"
          >{{ item.userType }}</md-table-cell
        > -->
        <md-table-cell md-label="Start" @click.native="gotoEval(item)">
          {{ item.startTime }}
        </md-table-cell>
        <md-table-cell md-label="Duration(min)" @click.native="gotoEval(item)">
          {{ item.screenDuration }}
        </md-table-cell>
        <md-table-cell md-label="Delete">
          <md-icon @click.native="DeleteClick(item)" class="delete-icon"
            >delete</md-icon
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
    <!-- <ListFooterLayout /> -->
  </div>
</template>

<script>
// import ListFooterLayout from "../../layouts/list-layout/ListFooterLayout.vue";
import { mapActions } from "vuex";
import { Success } from "../../../models/SwalMessages";

import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";

export default {
  inject: [
    "listInput",
    "list",
    "confirmation",
    "refreshList",
    "showSuccess",
    "listInput",
    "refresh",
    "convertUTCDateToLocalDate",
  ],
  name: "GlobeActivitySlimTable",
  components: {
    // ListFooterLayout,
  },
  created() {
    if (this.refresh.value) {
      this.refreshList();
      this.refresh.value = false;
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("screenings", ["deleteScreeningsAvailable"]),
    gotoEval(item) {
      this.$store.state.screeningeval.ReferralDoctors = [];
      this.$router.push({
        name: "ScreeningsEval",
        params: { id: item.screeningID, from: "screenings" },
      });
      // this.$router.push(this.$route.path + "/Evaluation/" + item.screeningID);
    },
    DeleteClick(selectedItem) {
      this.confirmation.title = "Delete Screening ?";
      this.confirmation.description =
        "Are you sure you want to delete this Screening ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteScreeningsAvailable(selectedItem.screeningID).then(() => {
          var success = new Success();
          success.text = "Screening deleted successfully";
          this.showSuccess(success);
          this.refreshList();
          this.submitSubscription.unsubscribe();
        });
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
        this.submitSubscription.unsubscribe();
      });
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
};
</script>
<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  // padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
  cursor: pointer;
}
.md-table-row:hover {
  background: #d4d5e6;
}

.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.delete-icon {
  cursor: pointer;
}
.Screeningsavailable.responsive td:nth-of-type(1):before {
  content: "Date";
}
.Screeningsavailable.responsive td:nth-of-type(2):before {
  content: "Name";
}
.Screeningsavailable.responsive td:nth-of-type(3):before {
  content: "Gender";
}
.Screeningsavailable.responsive td:nth-of-type(4):before {
  content: "Email";
}
.Screeningsavailable.responsive td:nth-of-type(5):before {
  content: "City/State/Zip";
}
.Screeningsavailable.responsive td:nth-of-type(6):before {
  content: "Device";
}
.Screeningsavailable.responsive td:nth-of-type(7):before {
  content: "Start";
}
.Screeningsavailable.responsive td:nth-of-type(8):before {
  content: "Duration";
}
.Screeningsavailable.responsive td:nth-of-type(9):before {
  content: "Delete";
}
</style>
